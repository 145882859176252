h1 {
  text-transform: uppercase;
  margin-top: 0;
}

hr {
  background-color: #ffc200;
  border: solid 0.1rem #ffc200;
  color: #ffc200;
  width: 2.9rem;
}

a:hover {
  text-decoration: none;
}

p.center-content {
  text-align: center;
}

@media screen and (min-width: 768px) {
  .pad {
    background-color: #0c0c0c;
    height: 8.6rem;
  }

  .header-id {
    bottom: 0;
    position: absolute;
    width: 100%;
    z-index: 100;
  }

  .header-wrap {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}

@media screen and (min-width: 992px) {
  .pad {
    height: 6.8rem;
  }
}

.header {
  background-color: #0c0c0c;
  padding: 0;
  width: 100%;
  z-index: 100;
}

.header a {
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .header ul {
    li {
      .active {
        border-bottom: 0.5rem solid #fff;
        display: inline;
        font-weight: bold;
        padding-bottom: 2.6rem;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .header button.share {
    font-size: 2rem;
    letter-spacing: 0.18rem;
    padding: 0;
    width: 100%;
    background-color: #0c0c0c;
    border: 0;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
    outline: 0;
    text-transform: uppercase;
  }
  .header button.share .share-img {
    padding-top: 1.8rem;
    position: absolute;
    right: 1.5rem;
  }
  .header button.share p.share-text {
    color: #fff;
    padding: 1.5rem;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .header button.share {
    font-size: 1.7rem;
  }
  .header button.share .share-img {
    padding-top: 0.6rem;
  }
  .header button.share p.share-text {
    color: #fff;
    padding: 0.6rem;
  }
}
@media screen and (max-width: 767px) {
  .header .networks {
    bottom: 6rem;
    display: none;
    position: absolute;
    right: 1rem;
    width: 3.5rem;
  }
  .header .networks img {
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 3.5rem;
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .header .networks {
    position: absolute;
    right: 1.7rem;
    top: 5.8rem;
    width: 3rem;
  }
  .header .networks img {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    width: 3rem;
  }
}
@media screen and (min-width: 992px) {
  .header .menu-social {
    list-style: none outside none;
    padding: 0;
    position: relative;
    transition: transform 0.5s cubic-bezier(0.625, 0.125, 0.305, 0.875);
    z-index: 11;
  }
  .header .menu-social:hover .menu-sub {
    visibility: visible;
  }
  .header .toggle-social {
    display: block;
    transition: all 0.25s cubic-bezier(0.875, 0.105, 0.19, 0.92);
  }
  .header .menu-sub {
    backface-visibility: hidden;
    list-style: none;
    margin: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    text-align: left;
    top: 4.8rem;
    transition: visibility 0.4s ease 0.4s;
    visibility: hidden;
    white-space: nowrap;
  }
  .header .menu-sub li {
    margin: 0;
    margin-left: -1rem;
    pointer-events: auto;
    transform: translateX(100%);
    transition: transform 0.35s cubic-bezier(0.75, -0.17, 0.23, 1.19);
  }

  .header .menu-sub li a {
    background-color: #0c0c0c;
    background-repeat: no-repeat;
    color: #fff;
    display: block;
    font-family: 'MagdaClean-Regular', 'Helvetica Neue', 'Arial', sans-serif;
    font-size: 1.4rem;
    font-weight: 800;
    padding: 1.5rem 1.5rem 1.2rem 2.4rem;
    position: relative;
    transition: background-color 0.5s ease-out;
    white-space: nowrap;
  }
  .header .menu-social:hover .menu-sub {
    transition-delay: 0s;
    visibility: visible;
  }
  .header .menu-item img {
    height: auto;
    margin-bottom: 0.2rem;
    margin-right: 1rem;
    width: 3rem;
  }
  .header .menu-item a:hover {
    background-image: linear-gradient(97deg, #f7971e, #ffd63f 70%);
  }
  .header .menu-social:hover .menu-sub li {
    transform: translateX(0);
  }
}
.header .input-toggler {
  display: none;
}
.header .mobile-logo {
  margin-left: 1.3rem;
  width: 8.1rem;
}
.header .tablet-logo {
  margin-left: 3.1rem;
}
.header .big-logo {
  height: 5.09rem;
  margin-left: 3.2rem;
  width: 10.04rem;
}
.header .share-icon {
  height: 2.5rem;
  margin-right: 2.5rem;
}
.header .navbar-collapse {
  height: 8.6rem;
}
.header .navbar-collapse .nav-link {
  color: #fff;
}
.header ul {
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2rem;
  letter-spacing: 0.18rem;
}
.header ul li {
  margin-left: 1.4rem;
  margin-right: 1.4rem;
}
.header .button-icon-menu {
  margin-right: 1.5rem;
  margin-top: 0;
  overflow: none;
  padding: 0;
}
.header .button-icon-menu label {
  margin-bottom: 0;
}
@media screen and (min-width: 768px) {
  .header .button-icon-menu {
    display: none;
  }
}
.header .button-icon-menu p {
  color: #fff;
  font-size: 0.8rem;
  margin: 0;
  text-transform: uppercase;
}
@media screen and (min-width: 768px) {
  .header ul li .active {
    border-bottom: 0.2rem solid #fff;
    display: inline;
    line-height: 4rem;
    padding-bottom: 2.9rem;
  }

  .header .navbar-nav {
    margin-left: 1.1rem !important; // Force margin from auto to get space for language selector
  }

  .header .navbar-nav .nav-item {
    margin: 1rem 1rem;
    position: relative;
  }
  .header .navbar-nav .nav-item::after {
    border-bottom: solid 0.3rem #ffc200;
    content: '';
    display: inline-block;
    left: 0;
    position: absolute;
    top: 6rem;
    transform: scaleX(0);
    transition: transform 0.2s cubic-bezier(0.7, 0, 0.4, 1);
    width: 100%;
  }
  .header .navbar-nav .nav-item:hover::after {
    transform: scaleX(1);
  }
  .header .navbar-nav .nav-item:hover .nav-link {
    text-shadow: 1px 0 #ffd63f;
    color: #ffd63f;
  }
}
@media screen and (min-width: 992px) {
  .header {
    height: 6.8rem;
    ul {
      li {
        .active {
          padding-bottom: 2rem;
        }
      }
    }
    .navbar-nav {
      margin-left: auto !important; // Set back margins to auto as language selector as enough space

      .nav-item {
        &::after {
          top: 5.1rem;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  .header ul.navbar-nav .nav-item {
    margin: 1rem 3.5rem;
    padding: 0 0.4rem;
  }
  .header .navbar-nav .nav-item::after {
    bottom: -1.5rem;
  }
}
@media screen and (max-width: 767px) {
  .header .menu-toggler {
    display: block;
    height: 2.5rem;
    position: relative;
    width: 2.5rem;
  }
  .header .menu-toggler-line {
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-name: slidein;
    animation-timing-function: ease-out;
    background: #fff;
    color: #fff;
    height: calc(30px / 10);
    left: 0;
    position: absolute;
    right: 0;
    transition-delay: 0s, 0.5s;
    transition-duration: 0.5s, 0.25s;
    transition-property: transform, opacity;
  }
  .header .menu-toggler-line:nth-child(2) {
    animation-duration: 0.6s;
    top: calc((30px / 5 * 3) / 2);
  }
  .header .menu-toggler-line:nth-child(3) {
    animation-duration: 0.1s;
    top: calc(30px / 5 * 3);
  }
  .header .collapse {
    display: none;
  }
  .header .input-toggler:checked ~ .collapse {
    display: block;
  }
  .header .input-toggler:checked ~ .menu-toggler p {
    color: #0c0c0c;
  }
  .header .input-toggler:checked ~ .menu-toggler .menu-toggler-line {
    background: #fff;
    transition-delay: 0.1s, 0s;
  }
  .header
    .input-toggler:checked
    ~ .menu-toggler
    .menu-toggler-line:nth-child(1) {
    transform: translateY(calc(30px / 5)) rotate(45deg);
  }
  .header
    .input-toggler:checked
    ~ .menu-toggler
    .menu-toggler-line:nth-child(2) {
    opacity: 0;
  }
  .header
    .input-toggler:checked
    ~ .menu-toggler
    .menu-toggler-line:nth-child(3) {
    transform: translateY(calc(30px / 5 * -2)) rotate(-45deg);
  }
  .header .navbar-collapse {
    z-index: 55;
    height: 100vh;
  }
  .header .navbar-collapse ul {
    border-top: 1px solid #4a4a4a;
  }
  .header .navbar-collapse ul li {
    background-color: #0c0c0c;
    border-bottom: 1px solid #4a4a4a;
    margin: 0;
    transition: background-color 0.5s ease-out;
  }
  .header .navbar-collapse ul li a.nav-link {
    padding: 1.5rem;
  }
  .header .navbar-collapse ul li .active {
    background-color: #ffd63f;
    background-image: linear-gradient(97deg, #f7971e, #ffcb00 70%);
  }
}
@media screen and (max-width: 767px) and (orientation: landscape) {
  .header .navbar-collapse ul li a.nav-link {
    font-size: 1.7rem;
    padding: 0.6rem;
  }
}
@media screen and (max-width: 767px) and (orientation: portrait) {
  .header .navbar-collapse ul li:last-of-type {
    border-bottom: 0;
    border-top: 1px solid #4a4a4a;
    bottom: 0;
    position: fixed;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .pad {
    height: 5.6rem;
  }
  .navbar-fixed-top {
    margin: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
  }
}

.btn {
  color: #fff;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  background-color: #ffc200;
  border: 0;
  letter-spacing: 0.18rem;
  text-transform: uppercase;
}
.btn:hover {
  background-color: #ffc200;
}

.banner h1 {
  color: #fff;
  font-family: 'Montserrat-Bold', 'Helvetica Neue', 'Arial', sans-serif;
  text-align: center;
}

.banner .btn {
  position: relative;
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  height: 5.88rem;
  letter-spacing: 0.18rem;
  margin-bottom: 2.02rem;
  text-align: center;
}
.banner .btn::after,
.banner .btn::before {
  border: 0.2rem solid #fff;
  bottom: 0;
  content: ' ';
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0);
  transition: transform 0.2s;
  z-index: 0;
}
.banner .btn:hover::before {
  transform: translate(3px, 3px);
}
.banner .btn:hover::after {
  transform: translate(-3px, -3px);
}
.banner .arrow {
  animation: arrow-opacity 2s linear infinite;
}

.lang-selector {
  position: absolute;
  right: 15%;
  top: auto;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
      font-size: 2rem;
      color: #fff;

      &:hover {
        color: #ffc200;
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .lang-selector {
    right: 5px;
    top: auto;
  }
}

@media screen and (min-width: 992px) {
  .lang-selector {
    right: 6%;
    top: auto;
  }
}

@media screen and (min-width: 1200px) {
  .lang-selector {
    right: 4%;
    top: auto;
  }
}

/* ===================================================
PROJECTS-FOOTER
=================================================== */

.projects-footer {
  background: url('/assets/img/background/bg-footer-mobile.png') no-repeat
    center;
  background: -webkit-image-set(
      url('/assets/img/background/bg-footer-mobile.png') 1x,
      url('/assets/img/background/bg-footer-mobile@2x.png') 2x,
      url('/assets/img/background/bg-footer-mobile@3x.png') 3x
    )
    no-repeat center;
  background-size: cover;
  height: 27rem;
  padding-top: 4.9rem;
}
.projects-footer .site-map,
.projects-footer a {
  color: #fff;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 1.1rem;
  padding-bottom: 3.3rem;
  padding-top: 2.8rem;
}
.projects-footer a p {
  color: #fff;
  font-family: 'Montserrat-Regular', 'Helvetica Neue', 'Arial', sans-serif;
  font-size: 2rem;
  letter-spacing: 0.1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}
.projects-footer .logo {
  margin-top: 2.3rem;
}
.projects-footer .logo img {
  margin-left: 2rem;
  margin-right: 2rem;
}
.projects-footer .logo img:hover {
  animation: pop 0.4s 2 cubic-bezier(0.08, 0.63, 0.18, 0.39);
}
@media screen and (min-width: 768px) {
  .projects-footer {
    background: url('/assets/img/background/bg-footer-tablet.png') no-repeat
      center;
    background: -webkit-image-set(
        url('/assets/img/background/bg-footer-tablet.png') 1x,
        url('/assets/img/background/bg-footer-tablet@2x.png') 2x,
        url('/assets/img/background/bg-footer-tablet@3x.png') 3x
      )
      no-repeat center;
    background-size: cover;
    padding-top: 8.1rem;
  }
  .projects-footer a p {
    font-size: 2rem;
    letter-spacing: 0.15rem;
  }
  .projects-footer .site-map,
  .projects-footer a {
    margin: 0;
  }
  .projects-footer .logo {
    margin-top: 3.4rem;
  }
  .projects-footer .logo img {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
@media screen and (min-width: 992px) {
  .projects-footer {
    background: url('/assets/img/background/bg-footer-desktop-1920.png')
      no-repeat center;
    background: -webkit-image-set(
        url('/assets/img/background/bg-footer-desktop-1920.png') 1x,
        url('/assets/img/background/bg-footer-desktop-1920@2x.png') 2x,
        url('/assets/img/background/bg-footer-desktop-1920@3x.png') 3x
      )
      no-repeat center;
    background-size: cover;
    height: 27rem;
    padding-top: 6rem;
  }
  .projects-footer a p {
    font-size: 3rem;
    letter-spacing: 0.18rem;
  }
  .projects-footer .site-map,
  .projects-footer a {
    padding-bottom: 2.4rem;
    padding-top: 3rem;
  }
  .projects-footer .logo {
    margin-top: 5.1rem;
  }
  .projects-footer .logo img {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
}
